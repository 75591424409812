body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  gap: 10px;
  cursor: pointer;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #517F83;
  color: #517F83;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: #517F83;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
.cabin-link {
  text-decoration: "none";
  max-width: "1200px";
  margin: "10px auto";
  transition: transform 0.3s ease, boxShadow 0.3s ease;
}

.cabin-link:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
}